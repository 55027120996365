<template>
  <travio-center-container pageTitle="Account Selector" gridWidth="1/2">
    <vx-card title="Destination Selector">
      <div class="flex">
        <p>
          Hi {{activeUserInfo.displayName}} - please select your start page:
        </p>
      </div>

      <h6 class="font-bold mt-6 mb-3">Companies</h6>
      <ul class="leftx">
        <li v-for="item in companyOptions" :key="item.code" >
          <vs-radio v-model="selectedPageItem" vs-name="selectedPageItem" :vs-value="item.code">{{ item.label }}</vs-radio>
        </li>
      </ul>
      
      <h6 class="font-bold mt-6 mb-3">Applications</h6>
      <ul class="leftx">
        <li v-for="item in applicationOptions" :key="item.code" >
          <vs-radio v-model="selectedPageItem" vs-name="selectedPageItem" :vs-value="item.code">{{ item.label }}</vs-radio>
        </li>
      </ul>

        <p v-if="!applicationOptions || applicationOptions.length == 0">
          No Applications to show.
        </p>

      <vs-checkbox class="mx-0 mt-6" v-model="rememberChoice">Remember my choice next time</vs-checkbox>
      <!-- <div class="clearfix mt-6">
        <vs-button @click="handleSubmit" type="border">Continue</vs-button>
        <router-link class="float-right" to="/account">My Account</router-link>
      </div> -->

      <div class="flex flex-wrap items-center justify-end">
        <vs-button to="/account" class="mr-4" type="border">My Account</vs-button>
        <vs-button @click="handleSubmit">Continue</vs-button>
      </div>
    </vx-card>
    
  </travio-center-container>
</template>

<script>
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import TravioAccessControl from '../../components/travio-pro/TravioAccessControl.vue';
import TravioCenterContainer from '../../components/travio-pro/TravioCenterContainer.vue';
export default {
  components: { TravioAccessControl, TravioCenterContainer },
  mixins: [TpNotificationMixin],
  data () {
    return {
      selectedPageItem: null,
      rememberChoice: true,
      companyOptions: [],
      applicationOptions: [],
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  mounted () {
    this.$http.get('api/accounts/selectorpage')
    .then(response => {
      this.selectedPageItem = response.data.selectedPageItem;
      // this.rememberChoice = response.data.selectedPageItem ? true : false
      this.companyOptions = response.data.companies;
      this.applicationOptions = response.data.applications;
    })
    .catch(error => {
      this.$_notifyFailure('Unable to load selector page options');
    });
  },
  methods: {
    handleSubmit () {
      this.$vs.loading();
      this.$http.post('api/accounts/selectorpage', { selectedPageItem: this.selectedPageItem, rememberChoice: this.rememberChoice })
        .then(response => this.$_notifySuccess('Successfully saved selected page'))
        .catch(error => this.$_notifyFailureByResponseData(error.response.data))
        .finally(() => this.$vs.loading.close())
    }
  }
}
</script>

<style>

</style>